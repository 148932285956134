import React from "react"
import { Link } from 'gatsby'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import './rts.scss'

const RTS = () => (
  <Layout>
    <SEO title="RTS - Works" />
    <div className='rts'>
      <h1><Link to='/works'>Works</Link><span>|</span>RTS</h1>
      <p>WebRTCを利用したファイル転送サービスです。</p>
      <a href='https://rts.zatsuzen.com' className='rts' target='_blank' rel='noopener'>
        <div className='title'>Real-Time File Transfer</div>
      </a>
    </div>
  </Layout>
)

export default RTS